import React from "react";
import Picture from "components/Picture";
import MediaContainer from "components/MediaContainer";

import videoPartners from "assets/images/homeowners/people.png";
import videoPartnersW from "assets/images/homeowners/people.webp";
import Button from "components/Button";
import background from "assets/images/homeowners/solar_homeowners.jpg";
import "./SolarHomeowner.scss";
import WrapperModalVideo from "./WrapperModalVideo";
export default function SolarHomeowner({
  setOpenFormPartnerIntake = () => {},
}) {
  return (
    <div className="solar-homeowner__container">
      <div
        className="solar-homeowner__background"
        style={{ backgroundImage: `url(${background})` }}
      />
      <div className="solar-homeowner__content">
        <h3 className="solar-homeowner__title">
          Saving the planet while saving money
        </h3>
        
        <div className="solar-homeowner__picture">
          <WrapperModalVideo videoId="7ZG6cN05KyA" showButton={true}>
            <MediaContainer>
              <Picture
                src={videoPartners}
                webp={videoPartnersW}
                alt="Partners"
              />
            </MediaContainer>
          </WrapperModalVideo>
        </div>
      </div>
    </div>
  );
}
