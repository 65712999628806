import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormSubmit from "components/forms/FormSubmit";
import InputText from "components/forms/InputText";
import FormSuccess from "components/forms/FormSuccess";
import FormError from "components/forms/FormError";
import FormLegals from "components/forms/FormLegals";

import { schema, resolver } from "data/form-schemas/contact.js";
import { sendContactForm } from "services/index.js";

// import "./ContactForm.scss";

export default function ContactForm({ emailTo = "mmurray@loanpal.com" }) {
  const [formStatus, setFormStatus] = useState("WAIT");
  const [submited, setSubmited] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(resolver),
  });

  const onSubmit = async ({ name, phone, emailFrom, address }) => {
    setSubmited(true);

    const formData = {
      name,
      emailFrom,
      phone,
      address,
      emailTo: emailTo,
      subject: `Loanpal mortgage inquiry from ${name}`,
    };

    try {
      const response = await sendContactForm(formData);
      setFormStatus("SENT");
    } catch (err) {
      setFormStatus("ERROR");
    }

    setSubmited(false);
  };

  return (
    <div className="contact-form">
      {formStatus === "SENT" && <FormSuccess />}

      {formStatus === "ERROR" && <FormError emailTo={emailTo} />}

      {formStatus === "WAIT" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>
            Simply enter your information below and one of our friendly team
            members will be in touch shortly.
          </h2>

          <p className="disclaimer">All fields are required</p>

          <InputText
            {...schema.name}
            register={register}
            errors={errors["name"]}
            value={watch("name")}
          />

          <InputText
            {...schema.phone}
            register={register}
            errors={errors["phone"]}
            value={watch("phone")}
          />

          <InputText
            {...schema.emailFrom}
            register={register}
            errors={errors["emailFrom"]}
            value={watch("emailFrom")}
          />

          <InputText
            {...schema.address}
            register={register}
            errors={errors["address"]}
            value={watch("address")}
          />

          <FormLegals />

          <FormSubmit data-emailto={emailTo} disabled={submited} />
        </form>
      )}
    </div>
  );
}
