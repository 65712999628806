import React, { useState } from "react";
import { Modal } from "react-responsive-modal";

import { useIsLargeMobileAndBelow } from "hooks/breakpoints";

import Main from "components/layout/Main";
import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import "./../components/Carousel.scss";
import PointerParent from "components/PointerParent";

import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import Picture from "components/Picture";
import Button from "components/Button";

import GridCards from "components/GridCards";
import CardBenefit from "components/CardBenefit";

import SolarElement from "components/SolarElement";
import { elements } from "data/what-we-do/solarElements.js";

import ContentGrid from "components/ContentGrid";
import ToolsCollageHomeowners from "components/ToolsCollageHomeowners";
import iconTransfer from "assets/images/what-we-do/icon_transfer_funds.png";
import iconTransferW from "assets/images/what-we-do/icon_transfer_funds.webp";
import "./../components/Carousel.scss";
import backgroundCover from "assets/images/header_skew_video/tami_dusk.jpg";
import backgroundCoverMobile from "assets/images/header_skew_video/tami_dusk_mobile.jpg";

import ParagraphPointer from "components/ParagraphPointer";
import SolarHomeowner from "components/SolarHomeowner";
import SolarRectangle from "components/SolarRectangle";

import SolarCustomerIntakeForm from "components/forms/SolarCustomerIntakeForm";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ContactForm from "components/forms/ContactForm";
import { Carousel } from "react-responsive-carousel";
import HomeOwnersCard from "components/HomeOwnersCard";
import HomeOwnersPicture from "assets/images/what-we-do/homeowners-1.jpg";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";

import ScrollToTop from "components/ScrollToTop";

export default function WhatWeDoPage() {
  const [openFormPartnerIntake, setOpenFormPartnerIntake] = useState(false);
  const [openFormSolarCustomer, setOpenFormSolarCustomer] = useState(false);
  const [openFormImprovement, setOpenFormImprovement] = useState(false);
  const [openFormRefinance, setOpenFormRefinance] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const isMobile = useIsLargeMobileAndBelow();

  const points = ["headingslim1"];

  const impacts = [
    "<p>“ It was so easy… the cost savings have been phenomenal.”</p><p style='text-align: right;'>- MIRANDA</p>",
    "<p>“ I was amazed how stress-free and straight-forward the process was.”</p><p style='text-align: right;'>- HECTOR</p>",
    "<p>“ I always look for honesty and I got that from Loanpal.”</p><p style='text-align: right;'>- FREDA</p>",
    "<p>“ They were always straight-forward. They were always transparent.”</p><p style='text-align: right;'>- ROBIN</p>",
  ];

  const params = {
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  return (
    <>
      <Main page={"what-we-do"} pagetitle="Homeowners">
        <HeaderSkew
          title="Driving sustainability forward, one house at a time"
          background={backgroundCover}
          background={isMobile ? backgroundCoverMobile : backgroundCover}
          url="what-we-do"
          backgroundColor="tami"
          appearance=""
        />
        <Block layer="1" appearance="left-face-mobile">
          <GridCards modifier={["4-small", "fullwidth", "carousel"]}>
            <Carousel
              autoPlay={true}
              interval={2500}
              infiniteLoop={true}
              showThumbs={false}
            >
              {impacts.map((impact, i) => (
                <CardBenefit
                  title={impact}
                  modifier={["transparent"]}
                  key={impact}
                />
              ))}
            </Carousel>
          </GridCards>
        </Block>
        <Block layer="1" appearance="left-face">
          <ContentGrid appearance="main-single">
            <GridCards modifier={["4-small", "fullwidth", "no-carousel"]}>
              {impacts.map((impact) => (
                <CardBenefit
                  title={impact}
                  modifier={["transparent"]}
                  key={impact}
                />
              ))}
            </GridCards>
          </ContentGrid>
        </Block>
        <Block
          layer="2"
          guides={0}
          appearance="regular"
          style={{ overflow: "visible" }}
        >
          <SolarHomeowner
            setOpenFormPartnerIntake={() => setOpenFormPartnerIntake(true)}
          />
        </Block>
        <Block
          layer="2"
          guides={2}
          appearance="white-gray-block"
          guideType="center-desktop"
        >
          <SolarRectangle
            text="There are countless ways to upgrade your home sustainably"
            index={1}
          />
        </Block>
        <div className="pointer-parent">
          {elements
            .filter(({ enabled }) => enabled)
            .map((el, index) => (
              <ParagraphPointer
                key={`solar-pointer-element-${index}`}
                linkedTo={`#wwd-se${index}`}
              />
            ))}

          <Block
            layer="1"
            name="About Us"
            guides={2}
            guideType="center-left-mobile"
            appearance="grid-solar-cards"
          >
            {elements
              .filter(({ enabled }) => enabled)
              .map((element, index) => (
                <SolarElement
                  key={`solar-element-${index}`}
                  {...element}
                  a
                  isEven={index % 2}
                  id={`wwd-se${index}`}
                />
              ))}
          </Block>
        </div>

        <PointerParent points={points} appearance="pointer-homeowners">
          <Block
            layer="2"
            name="About Us"
            appearance="grid-dual--reverse"
            guides="1"
            guideType="left-center"
          >
            <HomeOwnersCard image={HomeOwnersPicture}>
              <Picture
                src={iconTransfer}
                webp={iconTransferW}
                width="54"
                height="54"
                alt="Icon Transfer"
              />
              <Heading type="h3" appearance="h1--dark">
                Homeowners - Go Green and save even more
              </Heading>
              <Paragraph appearance="dark">
                Bundle your sustainable home improvement financing with your
                home loan to save even more money every month with a Go Green
                Refi. Special interest rates, expedited loan processing and no
                lender fees are just a few of the benefits of Loanpal’s Go Green
                home loan program.
              </Paragraph>

              <Button
                handleClick={() => setOpenFormRefinance(true)}
                appearance="slim-ghost"
                aria-label="Open form"
                caret
              >
                Learn How
              </Button>
            </HomeOwnersCard>
          </Block>

          {/* */}

          <Block
            layer="1"
            name="Tools to help"
            guides="1"
            guideType="left-center"
            appearance="offset-top"
            style={{
              overflow: "hidden",
              background: "#f7f8f7",
            }}
          >
            <ContentGrid appearance="tools-homeowner">
              <div id="t3">
                <div data-aos="fade-up">
                  <Heading
                    type="h2"
                    appearance="h1--dark-right "
                    id={points[0]}
                  >
                    Ask your contractor about the Loanpal Pros app today.
                  </Heading>

                  <Paragraph appearance="small-plus-right">
                    Loanpal Pros allows you to make real-time updates, compare
                    rates and term details, all from the convenient touch of a
                    mobile device. Submit an application, and get an instant
                    decision. This is finance made friendly.
                  </Paragraph>
                </div>
              </div>
              <div />
              <ToolsCollageHomeowners
                openVideo={() => onOpenModal("video-three")}
                data-aos="fade-in"
                slim
              />
            </ContentGrid>
          </Block>
          <ScrollToTop modifier={["slim-top"]} guides={0} />
        </PointerParent>
      </Main>

      <Modal
        open={openFormPartnerIntake}
        onClose={() => setOpenFormPartnerIntake(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <ContactForm emailTo="mmurray@loanpal.com" />
      </Modal>
      <Modal
        open={openFormSolarCustomer}
        onClose={() => setOpenFormSolarCustomer(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <SolarCustomerIntakeForm />
      </Modal>

      <Modal
        open={openFormImprovement}
        onClose={() => setOpenFormImprovement(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <>
          <ContactForm emailTo="solarinfo@loanpal.com" />
        </>
      </Modal>

      <Modal
        open={openFormRefinance}
        onClose={() => setOpenFormRefinance(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <ContactForm emailTo="mmurray@loanpal.com" />
      </Modal>
    </>
  );
}
