import React from "react";
import "./HomeOwnersCard.scss";

export default function HomeOwnersCard({ children, image }) {
  return (
    <div className="HomeOwnersCard__Container">
      <div className="HomeOwnersCard__PictureContainer">
        <img
          src={image}
          className="HomeOwnersCard__Picture"
          alt="Go Green and save even more"
        />
      </div>

      <div className="HomeOwnersCard__TextContainer">{children}</div>
    </div>
  );
}
